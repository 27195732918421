import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
 

const CourseGrid = (props) => {
  return (
    <section className="coursehighlight">
      <div className="container">
        <div className="coursehighlight__header">
          <h2 className="linethrough-header">Course Highlights</h2>
        </div>
      </div>
      <div className="coursegrid">
        <div className="row">
          <div
            className="coursegrid__one"
            style={{ background: `#e8e8e8` }}
          >
            <div className="coursegrid__content">
              <LazyLoadImage effect="blur" src={props.cardOneImage} alt={props.cardOneImageAlt} width="50%" />
              <h4>{props.cardOneTitle}</h4>
              <p>{props.cardOneText}</p>
            </div>
          </div>
          <div
            className="coursegrid__one"
            style={{
              background: `#e8e8e8`
            }}
          >
            <div className="coursegrid__content">
               <LazyLoadImage effect="blur" src={props.cardTwoImage} alt={props.cardTwoImageAlt} />
              <h4>{props.cardTwoTitle}</h4>
              <p>{props.cardTwoText}</p>
            </div>
          </div>
            <div
              className="coursegrid__one"
              style={{ background: `#e8e8e8` }}
            >
              <div className="coursegrid__content">
                 <LazyLoadImage effect="blur" src={props.cardThreeImage} alt={props.cardThreeImageAlt} />
                <h4>{props.cardThreeTitle}</h4>
                <p>{props.cardThreeText}</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CourseGrid;
