import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';

//components
import SchoolHeader from '../../components/mini/schoolheader';
import SchoolCard from '../../components/mini/schoolcard';
import CourseGrid from '../../components/mini/coursegrid';
import Carousel from '../../components/carousel';
import Tracks from '../../components/mini/multipletrack';
import settings from '../../../settings';
import BmwMiniSchoolGlance from '../../components/bmwGlance/bmwMiniSchoolGlance';

//images
const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const miniclassesbanner = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-classes-top-banner.jpg`;
const minitrackdrivecar = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-track-drive-box.jpg`;
const miniautocrosscar = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-autocross-school-box.jpg`;
const ministuntcar = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-stunt-driving-school-box.jpg`;
const minieastcoast = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-sc-school.png`;
const Forward180Turn = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-180-turn-excercise.svg`;
const JTurn = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-j-turn-exercise.svg`;
const ReverseTurn = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-reverse-turn-excercise.svg`;
const slideone = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-on-track-slide01.jpg`;
const slidetwo = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-on-track-slide02.jpg`;
const manualClass = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-manual-class.jpg`;
const slidethree = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-smiling-track-slide03.jpg`;

const carouselimages = [
  { id: 1, image: `${slideone}`, imageAlt: 'Mini On Track Slide01' },
  { id: 2, image: `${slidetwo}`, imageAlt: 'Mini On Track Slide02' },
  { id: 3, image: `${slidethree}`, imageAlt: 'Mini Smiling Track Slide03' }
];

const scrollToRef = () => {
  window.scrollTo({
    top: 1000,
    left: 0,
    behavior: 'smooth'
  });
};

const SchoolPage = () => (
  <Layout>
    <SEO title="MINI Stunt Driving School | Classes for the MINI Driving Experience" description="J-turns, 180-degree slides, maneuvering and handling, you'll be testing the limits of traction and the extraction of maximum smiles per hour!" />

    <div className="mini__hero">
      <LazyLoadImage effect="blur" src={miniclassesbanner} alt="MINI Classes" />
      <button className="arrow" onClick={scrollToRef} onKeyDown={scrollToRef}>
        <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
      </button>
    </div>
    <section className="school">
      <SchoolHeader
        title="mini driving experience classes"
        subtitle="MINIs are happiest when sliding around a track and being pushed to their limit. Take a class with us, and our professional driving instructors will show you how to extract maximum smiles per hour."
      />
      <SchoolCard
        image={minieastcoast}
        imageAlt="Mini Driving Experience At South Carolina Box"
        header="Mini Driving Experience At South Carolina"
        time="8:30AM-12PM, or 1PM-4:30PM"
        multipleTime
        price="$499"
        text="This action-packed half-day course gets you into a John Cooper Works MINI on our track. Start with an exhilarating drag race as two MINIs go up against each other. After your heart rate goes up, hop in for the Handling Course, which shows off the unique handling attributes that make MINIs such fun. Then it’s on to competitive Timed Autocross laps. End the event with a hot lap with one of our pro driving instructors, where the smiles come as fast as the day goes."
        monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"
        monthSliderQuery="&class=MINI_Driving_Experience"
      />
      <SchoolCard
        image={minitrackdrivecar}
        imageAlt="Mini Track Drive Box"
        header="MINI TRACK DRIVE"
        time="8:30AM-12PM, or 1PM-4:30PM"
        multipleTime
        price="$499"
        text="A half day with a whole lot of fun. Get in and get familiar with your MINI in short order, then get on the track for some handling, braking and autocross exercises. Try to keep up with our instructors as they take the lead position and guide you around the course. End the session with a hot lap, where an instructor will show you exactly what a MINI can do."
        monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"
        monthSliderQuery="&class=MINI_Track_Drive"
        note="Available at Thermal location only. Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
      />
      <SchoolCard
        image={miniautocrosscar}
        imageAlt="Mini Autocross School Box"
        header="mini autocross and track school"
        time="8AM-4PM "
        price="$750"
        text="Start your day with a buzz your morning coffee could never provide. You’ll slide behind the wheel of a MINI and take it for a spin on our warm-up slalom, then head into panic braking from increasing speeds. We keep the excitement up with wet skid pad training and emergency lane change maneuvering, then bring it all together for our handling course, where you’ll develop a true feel for the limit of traction. Finally, hop in for a hot lap with our instructors as they put an exclamation point on the day."
        monthSliderHeader="CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"
        monthSliderQuery="&class=MINI_Autocross_and_Track_School"
        note="Available at Thermal location only. Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
      />
    </section>

    <CourseGrid
      cardOneTitle="PANIC BRAKING"
      cardOneText="Learn to brake hard from speeds up to 55mph while navigating a corner. Sounds easy: it isn’t."
      cardTwoTitle="WET SKID PAD"
      cardTwoText="Take a literal spin on our polished concrete skid pad. Wet down constantly, the wet polished surface prevents full grip from being achieved, which means under- and oversteer conditions for you, the driver. Perfect for learning how the MINI handles at the limit of adhesion."
      cardThreeTitle="HANDLING COURSE"
      cardThreeText="Students will get an overall feel for a MINI as they drive through our handling course. Developing a feel for the limit of traction while accelerating, braking and cornering, it’s an exercise aimed at overall car control."
    />
    <section className="drivingschool__tracks">
      <div className="container">
        <div className="drivingschool__tracks__header">
          <h1 className="linethrough-header">Mini fun, now in two locations</h1>
        </div>
      </div>
    </section>
    <Tracks />
    <BmwMiniSchoolGlance/>
  </Layout>
);

export default SchoolPage;
