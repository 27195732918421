import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwMiniSchoolGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>MINI DRIVING EXPERIENCE SCHOOLS AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>How many MINI Driving Experience locations are there?</h5>
                <p>We currently have two locations in California and South Carolina.</p>
              </div>
              <div>
                <h5>Where is the MINI Driving Experience located?</h5>
                <p>We currently have two locations in California and South Carolina.</p>
              </div>
              <div>
                <h5>BMW Performance Center</h5>
                <p>155 SC-101, Greer, SC 29651<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>BMW Performance Center West</h5>
                <p>86-050 Jasper Lane, Thermal, CA 92274<br/> (888) 345-4269</p>
              </div>
              <div>
                <h5>Is the MINI Driving Experience affiliated with area hotels?</h5>
                <p>Please visit our <a href="/hotelpartners">Hotels Page</a> for more information.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Is it hard to drive a MINI?</h5>
                <p>
                Not at all. MINIs are friendly, with sprightly engines and responsive handling. You’ll have a great time behind the wheel.
                </p>
              </div>
              <div>
                <h5>How do you drive a MINI car?</h5>
                <p>
                We currently have a MINI Track Drive and a MINI Autocross School. Each will explore how a MINI handles, brakes and accelerates on a closed track.
                </p>
              </div>
              <div>
                <h5>Is there a MINI Cooper Driving School?</h5>
                <p>
                Yes. The MINI Driving Experience offers classes in two locations, with pro instructors and a fleet of MINIs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwMiniSchoolGlance;